import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy"
export default class extends Controller {
  connect() {
    $(this.element).on('blur', () => this.copyValue());
  }

  copyValue() {
    const sourceValue = $(this.element).val();

    const relatedAttrId = $(this.element).data('relatedAttrId');

    const relatedElement = $('#' + relatedAttrId);
    relatedElement.val(sourceValue);
  }
}
