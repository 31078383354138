import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-form"
export default class extends Controller {
  connect() {
    this.quoteUserRoleName = this.element.dataset.quoteUserRoleName;
    this.quoteSupervisorRoleName = this.element.dataset.quoteSupervisorRoleName;
    this.selectedRoles = '';

    window.onload = () => {
      this.toggle_quotes_section();
    };
  }

  enable2FA(event) {
    if (event.target.checked)
      $('.qr-code-secret-key').fadeIn('fast');
    else
      $('.qr-code-secret-key').fadeOut('fast');
  }

  toggle_quotes_section(event){
    this.selectedRoles = $("#user_roles").find(':selected').map(function() { return this.value; }).get();

    if($.inArray(this.quoteUserRoleName, this.selectedRoles) !== -1){
      $("#user_region_ids").select2('enable');
      $("#user_reseller_ids").select2('enable');
      $("#user_quote_group_ids").select2('enable');
    }
    else if($.inArray(this.quoteSupervisorRoleName, this.selectedRoles) !== -1) {
      $("#user_region_ids").select2('enable');
      $("#user_reseller_ids").select2('enable');
    } else {
      $("#user_region_ids").select2('enable', false);
      $("#user_reseller_ids").select2('enable', false);
      $("#user_quote_group_ids").select2('enable', false);
    }
  }
}
