import { Controller } from "@hotwired/stimulus";
import Quill from 'quill';

export default class extends Controller {
    static targets = ['editor', 'hiddenInput'];

    connect() {
        this.quill = new Quill(this.editorTarget, {
            theme: 'snow',
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                    [{ 'align': [] }],
                    ['link', 'image'],
                    ['clean']
                ]
            },
        });

        this.quill.on('text-change', () => {
            this.hiddenInputTarget.value = this.quill.root.innerHTML;
        });
    }
}
