import { Controller } from "@hotwired/stimulus"
import '../plugins/datatables';
import "floatthead"

export default class extends Controller {
  static targets = ["table"]

  connect() {
    let that = this;
    if(that.element.classList.contains('datatable')) {
      this.initDataTable();
    }
    if(that.element.classList.contains('fixed-table-header'))
      this.enableFixedTableHeader();
  }

  initDataTable() {
    let that = this;
    let tableId = '#' + that.tableTarget.id;

    $.fn.dataTable.moment('DD.MM.YYYY');

    $(tableId).DataTable({
      retrieve: true,
      "paging":       true,
      "searching":    false,
      "info":         false,
      "pagingType":   "numbers",
      "pageLength": 5,
      "lengthChange": false,
      "order": [],
      "dom": "<'d-flex justify-content-start' f><'d-flex justify-content-end text-sm-right' B>" +
          "tr i" +
          "<'my-1 " + that.tableTarget.id + "_pagination'<'float-end' p>>",
      "initComplete": function() {
        if (this.api().page.info().pages === 1) {
          $("." + that.tableTarget.id + "_pagination").hide();
        }
      }
    });
  }

  enableFixedTableHeader() {
    let tableId = '#' + this.tableTarget.id
    let table = $(tableId);
    let width = table.width();
    // todo Fixed header not working when screen is sized for mobile (menu changed)
    let focusElement = $(document.activeElement)
    if( width > 992 ) {
      table.floatThead({
        autoReflow: true,
        top: 100,
        responsiveContainer: function($table){
          return $table.closest(".table-responsive-sm");
        }
      }).on("floatThead", function(e, isFloated, $floatContainer) {
        if (isFloated) {
          table.addClass("fixedHeader-floating");
        } else {
          table.removeClass("fixedHeader-floating");
        }
      });

      setTimeout(function () {
        focusElement.trigger("reflow");
      }, 300);
    }
  }
}