import { Controller } from "@hotwired/stimulus"
import "bootstrap-datepicker"

export default class extends Controller {
  connect() {
    this.initDatepicker()
  }

  initDatepicker() {
    this.initGeneralDatepicker();
    this.initMonthDatepicker();
  }

  initGeneralDatepicker() {
    let $datepicker = $('.datepicker');

    function init($this) {
      let options = {
        disableTouchKeyboard: false,
        autoclose: true,
        format: 'dd.mm.yyyy',
        clearBtn: true
      };

      $this.datepicker(options);
    }

    if ($datepicker.length) {
      $datepicker.each(function() {
        init($(this));
      });
    }
  }

  initMonthDatepicker() {
    let $monthDatepicker = $('.month-datepicker');

    function init($this) {
      let options = {
        defaultDate: Date.now(),
        viewMode: 'months',
        minViewMode: 'months',
        format: 'MM yyyy',
        todayHighlight: true,
        showClear: true
      };

      $this.datepicker(options);
    }

    if ($monthDatepicker.length) {
      $monthDatepicker.each(function() {
        init($(this));
      });
    }
  }

}