import { Controller } from "@hotwired/stimulus"

import "../plugins/jquery.bootstrap.wizard"
import Swal from "sweetalert2";
// import "../plugins/argon/multistep-form"

// import "../plugins/"
// require("../plugins/wizard");

// Connects to data-controller="wizard"
export default class extends Controller {

  connect() {
    $('#wizard').bootstrapWizard()
  }

  productChange(event) {
    event.preventDefault();
    const url = $(event.target).data().updateUrl;

    $.ajax({type: 'GET',
      url: url,
      data: {
        product_id: $("#product_select")[0].value
      },
      dataType: 'script'
    });
  }

  productNext(){
    this.submitForm($('#products-form'))

    // The "bootstrapWizard('previous')" is actually needed. Otherwise the wizard will jump to the last step
    $('.card.card-wizard').bootstrapWizard('show', 1);
  }

  submitForm(form) {
    $.ajax({
      type: 'GET',
      url: form[0].action,
      data: form.serialize(),
      dataType: 'script'
    });
  }

  findAllSelectedCheckboxes(checkboxes) {
    return Array.from(checkboxes).filter(
      checkbox => checkbox.checked,
    );
  }

}
