import {Controller} from "@hotwired/stimulus"
import ClipboardJS from "clipboard";


export default class extends Controller {

  connect() {
    this.initClipboard();
  }

  initClipboard() {
    const that = this;

    // https://stackoverflow.com/questions/37381640/tooltips-highlight-animation-with-clipboard-js-click/37395225
    $('.clipboard').tooltip({
      trigger: 'click'
    });

    // Clipboard
    var clipboard = new ClipboardJS('.clipboard');

    clipboard.on('success', function (e) {
      let tooltip = that.setTooltip(e.trigger, 'Copied!');
      that.hideTooltip(tooltip);
    });

    clipboard.on('error', function (e) {
      let tooltip = that.setTooltip(e.trigger, 'Failed!');
      that.hideTooltip(tooltip);
    });
  }

  setTooltip(btn, message) {
    let tooltip = new Bootstrap.Tooltip($(btn), {
      placement: 'top',
      title: message,
      trigger: 'manual'
    })
    tooltip.show()

    return tooltip
  }

  hideTooltip(tooltip) {
    setTimeout(function () {
      tooltip.dispose()
    }, 1000)
  }
}
