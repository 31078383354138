import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard-widgets"
export default class extends Controller {
  static targets = ["grid"]

  widgetReady() {
    new Masonry(this.gridTarget, {
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true
    });
  }
}
