import { Controller } from "@hotwired/stimulus"
require('select2')()
require("../plugins/custom_select2")

export default class extends Controller {

  connect() {
    this.renderSelect()
  }

  mountEvent(self) {
    if (! this.element.classList.contains('onchange-send-ajax-form')) {
      let event = new Event('change', {bubbles: true}) // fire a native event
      self.dispatchEvent(event)
    }
  }

  renderSelect() {
    let that = this

    this.renderSelect2()

    $(that.element).on('select2:select', function () {
      that.mountEvent(this)
    })

    $(that.element).on('select2:unselect', function (evt) {
      if (evt.params) {
        if (!evt.params.originalEvent) {
          return
        }
        evt.params.originalEvent.stopPropagation()
      }
      that.mountEvent(this)
    })

    $(that.element).on('change.select2', function (e) {
      that.initCount(that.element)
      that.renderDropdownAlwaysOneSide(that.element)
    })

    this.initCount(that.element)
  }

  initCount(element){
    if(element.multiple){
      let dataCount = element.dataset.count || element.options.length
      if(dataCount) {
        if ($(element).siblings('span.select2').find('.data-count').length > 0){
          $(element).siblings('span.select2').find('.data-count').html($(element).select2('data').length + '/' + dataCount)
        } else {
          let span = '<span class="data-count text-muted">' + $(element).select2('data').length + '/' + dataCount + '</span>'
          $(element).siblings('span.select2').find('.select2-selection--multiple').append(span)
        }
        let selectionRendered = $(element).siblings('span.select2').find('.select2-selection--multiple')[0]
        selectionRendered.style.paddingRight = $(element).siblings('span.select2').find('.data-count').width() + 'px'
      }
    }
  }

  renderSelect2() {
    let that = this
    let element = that.element
    let width = element.dataset['width'] || null
    let dropdownClass = element.dataset['dropdownClass'] || '.modal'
    let disabledButtons = element.dataset['disabledButtons'] || false
    let autoClose = element.dataset['autoClose'] || true
    let maximumSelectionLength = element.dataset['maximum_selection_length'] || 0

    let dropdownParent = (element.closest(dropdownClass) && element.closest(dropdownClass).id) ? $('#' + element.closest(dropdownClass).id + ' .modal-content')[0] : $(document.body)

    let selectObj
    if (element.classList.contains('no-searchbox')) {
      selectObj = $(element).select2({
        theme: 'bootstrap-5',
        width: width ? width : 'auto',
        placeholder: "Please select...",
        dropdownParent: dropdownParent,
        minimumResultsForSearch: -1
      })
    }
    else if (element.classList.contains('select2-with-deselect-vc')) {
      selectObj = $(element).select2({
        theme: 'bootstrap-5',
        width: width ? width : '100%',
        placeholder: "Please select...",
        allowClear: true,
        dropdownParent: dropdownParent
      })
    }
    else if (element.classList.contains('select2-ajax-free-tags-vc')) {
      let ajax_url = element.dataset['ajaxUrl']
      let tags = element.dataset['tags']
      let value_method = element.dataset['valueMethod'] || 'id'
      let text_method = element.dataset['textMethod'] || 'name'
      let page_limit = element.dataset['pageLimit'] || 200
      selectObj = $(element).select2({
        theme: 'bootstrap-5',
        width: width ? width : '100%',
        placeholder: 'Please select...',
        dropdownAdapter: $.fn.select2.amd.require('select2/selectAllAdapter'),
        dropdownParent: dropdownParent,
        tags: (tags ? tags : true),
        tokenSeparators: [',', ' '],
        multiple: true,
        ajax: {
          url: ajax_url,
          dataType: 'json',
          quietMillis: 100,
          data: function (params) {
            return {
              search: params.term,
              selected: '',
              value_method: value_method,
              text_method: text_method,
              page_limit: page_limit, // page size
              page: params.page || 1      // page number
            }
          },
          processResults: function (data, params) {
            params.page = params.page || 1
            let more = (params.page * page_limit) < data.count
            let newOptions = data.results.map(function (d) {
              if ($(element).find("option[value='" + d.id + "']").length) {
                return null
              } else {
                return new Option(d.text, d.id, false, false)
              }
            })
            this.$element.append(newOptions)
            element.dataset.count = data.count
            setTimeout(function () {
              that.initCount(element)
            }, 100)
            return {results: data.results, pagination: {more: more}}
          },
          cache: true
        }
      })
    }
    else if (element.classList.contains('select2-with-ajax-url')) {
      let ajax_url = element.dataset['ajaxUrl']
      let value_method = element.dataset['valueMethod'] || 'id'
      let text_method = element.dataset['textMethod'] || 'name'
      let page_limit = element.dataset['pageLimit'] || 200
      selectObj = $(element).select2({
        theme: 'bootstrap-5',
        width: width ? width : '100%',
        placeholder: 'Please select...',
        dropdownParent: dropdownParent,
        dropdownAdapter: (element.multiple && !disabledButtons) ? $.fn.select2.amd.require('select2/selectAllAdapter') : null,
        multiple: element.multiple,
        ajax: {
          url: ajax_url,
          dataType: 'json',
          quietMillis: 100,
          data: function (params) {
            return {
              search: params.term,
              selected: '',
              value_method: value_method,
              text_method: text_method,
              page_limit: page_limit, // page size
              page: params.page || 1     // page number
            }
          },
          processResults: function (data, params) {
            params.page = params.page || 1
            let more = (params.page * page_limit) < data.count
            let newOptions = data.results.map(function (d) {
              if ($(element).find("option[value='" + d.id + "']").length) {
                return null
              } else {
                return new Option(d.text, d.id, false, false)
              }
            })
            this.$element.append(newOptions)
            element.dataset.count = data.count
            setTimeout(function () {
              that.initCount(element)
            }, 100)
            return {results: data.results, pagination: {more: more}}
          },
          cache: true
        },
        templateSelection: function(option) {
          return that.formatSelectedOption(option, that.element)
        },
        escapeMarkup: function(markup) {
          return markup
        },
      })
    }
    else {
      selectObj = $(element).select2({
        theme: 'bootstrap-5',
        width: width ? width : '100%',
        placeholder: 'Please select...',
        templateSelection: function(option) {
            return that.formatSelectedOption(option, that.element)
        },
        escapeMarkup: function(markup) {
          return markup
        },
        dropdownAdapter: (element.multiple && !disabledButtons) ? $.fn.select2.amd.require('select2/selectAllAdapter') : null,
        dropdownParent: dropdownParent,
        maximumSelectionLength: maximumSelectionLength
      })
    }

    if (element.classList.contains('onchange-send-ajax-form')) {
      let submitSelect2 = new Event('submit-select2', {bubbles: true}) // fire a native event
      selectObj.on('change', function () {
        if ($(this).hasClass('select2-hidden-accessible') && autoClose === true)
          $(this).select2('close')
        this.dispatchEvent(submitSelect2)
      })

      selectObj.on('submit-select2', function (e) {
        let name = this.name
        if ($(this).attr('data-ajax-form-action')) {
          let method = $(this).attr('data-method') || 'GET'
          let form = $(this).parents('form').eq(0)
          $.ajax({
            method: method,
            url: $(this).attr('data-ajax-form-action'),
            data: form.serialize() + '&element=' + name,
            dataType: 'script'
          })
        }
      })
    }
  }

  formatSelectedOption(option, element) {
    let faIcon = element.dataset['faIcon']
    let disabledIcon = element.dataset['disabledIcon'] || false

    if (faIcon && !disabledIcon) {
      // Use an icon to show that only one item is selected
      return '<i class="fal fa-lg ' + faIcon +' me-2"></i>' + option.text
    } else {
      return option.text
    }
  }

  renderDropdownAlwaysOneSide(element) {
    const dropdownSide = element.dataset['dropdownSide'];
    if (!dropdownSide) return;

    const dropdown = $('.select2-dropdown');
    const targetClass = `select2-dropdown--${dropdownSide}`;
    const otherClasses = 'select2-dropdown--above select2-dropdown--below';

    dropdown.removeClass(otherClasses).addClass(targetClass);
  }
}
