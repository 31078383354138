import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  appendConfig() {
    let element = this.element;
    let appendText = element.dataset['appendText']

    document.getElementById("config-options-area").insertAdjacentHTML('beforeend', appendText);
  }
  removeConfig() {
    let element = this.element;

    $(element).parent().parent().fadeOut(100, function () {
      $(this).empty();
      return false;
    });
  }
}
