import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["machineTypeSelect", "contractualUnitSelect"]

  connect() {
    this.itemPrices = JSON.parse(this.element.dataset.itemPrices)
  }

  updateContractualUnits() {
    const selectedMachineTypeId = this.machineTypeSelectTarget.value
    const contractualUnits = this.getContractualUnitsForMachineType(selectedMachineTypeId)
    this.populateContractualUnitsSelect(contractualUnits)
  }

  getContractualUnitsForMachineType(machineTypeId) {
    const itemPricesForMachineType = this.itemPrices.find(item => item.machine_type_id == machineTypeId);
    return itemPricesForMachineType.data
  }

  populateContractualUnitsSelect(contractualUnits) {
    this.contractualUnitSelectTarget.innerHTML = ""

    this.addEmptyOption(this.contractualUnitSelectTarget);

    contractualUnits.forEach(unit => {
      const option = document.createElement("option")
      option.value = unit.item_price_id
      option.textContent = unit.contractual_unit_name

      this.contractualUnitSelectTarget.appendChild(option)
    })
  }

  addEmptyOption(target) {
    const emptyOption = document.createElement("option");
    emptyOption.value = "";
    emptyOption.textContent = "Select a unit";
    emptyOption.disabled = true;
    emptyOption.selected = true;

    target.appendChild(emptyOption);
  }

}
