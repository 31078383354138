import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card', 'button'];

  connect() {
    this.selectText = this.element.dataset.selectText;
    this.selectedText = this.element.dataset.selectedText;
    this.cards = this.cardTargets;
    this.buttons = this.buttonTargets;
  }

  selectCard(event) {
    const selectedCard = event.currentTarget;
    const input = selectedCard.querySelector('input');

    const activeBgColorClass = 'bg-primary';
    const inactiveBtnClass = 'btn-outline-primary';
    const activeBtnClass = 'btn-primary';

    this.cards.forEach(card => {
      card.querySelector('.card-header').classList.remove(activeBgColorClass);
      card.querySelector('input').checked = false;
    });

    selectedCard.querySelector('.card-header').classList.add(activeBgColorClass);
    input.checked = true;

    const selectedButton = event.currentTarget.querySelector(`.${inactiveBtnClass}`);

    if (selectedButton) {
      this.buttons.forEach(button => {
        button.classList.remove(activeBtnClass);
        button.classList.add(inactiveBtnClass);
        button.innerHTML= this.selectText;
      });

      selectedButton.classList.remove(inactiveBtnClass)
      selectedButton.classList.add(activeBtnClass);
      selectedButton.innerHTML = this.selectedText;
    }
  }
}
