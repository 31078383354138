import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="storage-array-form"
export default class extends Controller {
  connect() {
    $('input#storage_array_service_deactivation_date').on('change', (event) => { this.toggleVisibility(event) });
  }

  toggleVisibility(event){
    const element = $(event.target);
    const deactivationReason = $('#' + element.data('deactivationReasonId'));


    if (element.val()){
      deactivationReason.removeClass('d-none');
    } else {
      deactivationReason.addClass('d-none');
    }
  }

  send_configuration(event){
    const element = $(event.target);
    const type = element.data('type') || 'GET';
    const url = element.data('url');
    const dataForm = $('#' + element.data('dataFormId'));

    $.ajax({
      type: type,
      url: url,
      data: dataForm.serialize() });
  }
}
