import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  connect() {
    let that = this;

    $(that.element).on('focus', function (el) {
      that.focused(this);
    });
    $(that.element).on('focusout', function () {
      that.defocused(this);
    });

    if (that.element.autofocus){
      setTimeout(function(){ $(that.element).focus(); }, 100)
    }
  }

  // when input is focused add focused class for style
  focused(el) {
    if (el.parentElement.classList.contains('input-group')) {
      el.parentElement.classList.add('focused');
    }
  }

  // when input is focused remove focused class for style
  defocused(el) {
    if (el.parentElement.classList.contains('input-group')) {
      el.parentElement.classList.remove('focused');
    }
  }
}