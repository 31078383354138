import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

window.Swal = Swal;
window.showSuccessSwalDialog = (title) => {
    Swal.fire({
        title: title,
        text: null,
        icon: 'success',
        customClass: {
            confirmButton: 'btn btn-primary swal2-confirm'
        },
        buttonsStyling: false
    });
}

// Behavior after click to confirm button
const confirmed = (element, result) => {
    if (result.value) {
        let dataRemote = !!element.getAttribute('data-remote');
        if (dataRemote) {
            $.ajax({
                method: element.getAttribute('data-method') || 'GET',
                url: element.getAttribute('href'),
                dataType: 'script',
                headers: {
                    'X-CSRF-Token': Rails.csrfToken()
                },
                error: function(xhr) {
                    let title   = 'Error!';
                    let message = 'Something went wrong. Please try again later.';

                    if (xhr.responseJSON && xhr.responseJSON.message) {
                        message = xhr.responseJSON.message;
                    }

                    Swal.fire(title, message,'error');
                }
            });
        } else {
            element.removeAttribute('data-confirm-swal');
            element.click();
        }
    }
};

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
    let message = element.getAttribute('data-confirm-swal');
    let text = element.getAttribute('data-text');
    let confirmButtonText = element.getAttribute('data-confirm-button-text') || 'Yes';
    let cancelButtonText = element.getAttribute('data-cancel-button-text')  || 'Cancel';
    Swal.fire({
        title: message,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        customClass: {
            confirmButton: 'btn btn-danger me-2 swal2-confirm',
            cancelButton: 'btn btn-primary swal2-cancel'
        },
        buttonsStyling: false
    }).then(result => confirmed(element, result));
};

const allowAction = (element) => {
    if (element.getAttribute('data-confirm-swal') === null) {
        return true;
    }

    showConfirmationDialog(element);
    return false;
};

function handleConfirm(element) {
    if (!allowAction(this)) {
        Rails.stopEverything(element);
    }
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm);
Rails.delegate(document, 'button[data-confirm-swal]', 'click', handleConfirm);