// Entry point for the build script in your package.json
import * as Turbo from "@hotwired/turbo-rails";
import Rails from "@rails/ujs";

import "./plugins/jquery";
// import "./plugins/jquery-ui-1.13.2/jquery-ui.min";

import "./plugins/highcharts";
import "./plugins/moment";
import "./plugins/cookie_custom";

import * as Bootstrap from "bootstrap";
import "bootstrap-notify";

import "select2/dist/js/select2.min";

import Masonry from "masonry-layout/masonry";

import "trix";
import "@rails/actiontext";
import "./custom_functions/swal_function";
import "./peaq/main";

window.bootstrap = Bootstrap;
window.Turbo = Turbo;
window.Rails = Rails;
window.Masonry = Masonry;

Rails.start();

import "./controllers";


document.addEventListener('turbo:load', function () {
    $(document).find('input[autofocus]').focus();
    autofillName();
    initCookiesEu();
    enableBootstrapTooltips();
    reflowFloatThead();
});

document.addEventListener('turbo:frame-load', function () {
    $(document).find('input[autofocus]').focus();
    enableBootstrapTooltips();
    reflowFloatThead();
});
